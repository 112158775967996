import React from 'react';
import { Link } from 'gatsby';

import { link, oldRuleHeader, rulesList } from './old-rule.module.scss';

import { ESharedFileRelation } from '../../models/shared-file.model';
import useTranslations from '../../hooks/use-translations';
import { useOldRules } from '../../hooks/use-old-rule';

const OldRule = () => {
    const t = useTranslations('SignUp');

    const oldRules = useOldRules(ESharedFileRelation.oldRule);
    const baseUrl = typeof window !== 'undefined' ? window.location.origin : '';

    return (
        <div>
            <div className={oldRuleHeader}>
                <p>{t.previousRules}</p>
            </div>
            <ul className={rulesList}>
                {oldRules?.map((rule, index) => (
                    <Link key={index} to={`/${rule.slug}.pdf`} className={link} target={'_blank'}>
                        {`${baseUrl}/${rule.slug}.pdf`}
                    </Link>
                ))}
            </ul>
        </div>
    );
};
export default OldRule;
