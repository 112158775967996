import { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { ESharedFileRelation, ISharedFile } from '../models/shared-file.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';

interface IUseSharedFileQueryResult {
    allOldRule: IQueryAllResult<ISharedFile>;
}

export const useOldRules = (relation: ESharedFileRelation) => {
    const { locale } = usePageContext();

    const { allOldRule } = useStaticQuery<IUseSharedFileQueryResult>(query);
    const oldRules = useMemo(() => {
        return getNodes(allOldRule);
    }, [allOldRule]);

    const [files, setFiles] = useState(getSharedFile(relation, locale, oldRules));

    useEffect(() => {
        setFiles(getSharedFile(relation, locale, oldRules));
    }, [relation, locale, oldRules]);

    if (!files.length || !files[0]?.media[0]) return;

    return files;
};

function getSharedFile(relation: ESharedFileRelation, locale: string, files: ISharedFile[]) {
    return files.filter((file) => file.locale === locale && file.relation === relation);
}

const query = graphql`
    query {
        allOldRule {
            edges {
                node {
                    linkId
                    relation
                    locale
                    slug
                    media {
                        ...mediaFields
                    }
                }
            }
        }
    }
`;
